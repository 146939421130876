// extracted by mini-css-extract-plugin
export var allCodeBoxControls = "codeBox-module--all-code-box-controls--KRVRO";
export var antRow = "codeBox-module--ant-row--df6Zb";
export var antRowRtl = "codeBox-module--ant-row-rtl--Xr6DW";
export var antTabsNavList = "codeBox-module--ant-tabs-nav-list--Zfb74";
export var antTooltipOpen = "codeBox-module--ant-tooltip-open--Svfaj";
export var anticon = "codeBox-module--anticon--MR7zl";
export var anticonCheck = "codeBox-module--anticon-check--5XV9A";
export var codeBox = "codeBox-module--code-box--WPj9S";
export var codeBoxActions = "codeBox-module--code-box-actions--a8ua8";
export var codeBoxCodeAction = "codeBox-module--code-box-code-action--WanwA";
export var codeBoxCodeCopy = "codeBox-module--code-box-code-copy--p9IY7";
export var codeBoxCodepen = "codeBox-module--code-box-codepen--lBD7A";
export var codeBoxCodesandbox = "codeBox-module--code-box-codesandbox--0ypSc";
export var codeBoxDebug = "codeBox-module--code-box-debug--MzUQn";
export var codeBoxDemo = "codeBox-module--code-box-demo--uhp7d";
export var codeBoxDescription = "codeBox-module--code-box-description--Kv-gY";
export var codeBoxExpandTrigger = "codeBox-module--code-box-expand-trigger--gKLuJ";
export var codeBoxMeta = "codeBox-module--code-box-meta--gPJFi";
export var codeBoxRiddle = "codeBox-module--code-box-riddle--zaE9e";
export var codeBoxTitle = "codeBox-module--code-box-title--pYcrS";
export var codeBoxesCol11 = "codeBox-module--code-boxes-col-1-1--Hf+05";
export var codeBoxesCol21 = "codeBox-module--code-boxes-col-2-1--D7APQ";
export var codeExpandIcon = "codeBox-module--code-expand-icon--utKen";
export var codeExpandIconHide = "codeBox-module--code-expand-icon-hide--07ewH";
export var codeExpandIconShow = "codeBox-module--code-expand-icon-show--x2FYb";
export var componentsPopconfirmDemoPlacement = "codeBox-module--components-popconfirm-demo-placement--Mhvgi";
export var componentsPopoverDemoPlacement = "codeBox-module--components-popover-demo-placement--oc-oD";
export var componentsTooltipDemoPlacement = "codeBox-module--components-tooltip-demo-placement--oAGcP";
export var editButton = "codeBox-module--edit-button--nRivt";
export var expand = "codeBox-module--expand--dnONm";
export var highlight = "codeBox-module--highlight--FQWfy";
export var highlightWrapper = "codeBox-module--highlight-wrapper--FrA7j";
export var highlightWrapperExpand = "codeBox-module--highlight-wrapper-expand--ovi0y";
export var markdown = "codeBox-module--markdown--INXoC";